import { Button, notification } from 'antd';

import { ARTIST_STATUS } from 'shared/constants/app';
import React from 'react';
import birthDeathTypes from '../../../../src/utils/birth-death-types';
import copyToClipboard from 'shared/tools/copy-to-clipboard';
import { getAuthorUrl, searchAuthorAllLotsURL, searchAuthorPublishedLotsURL } from 'shared/tools/url';

export const ARTIST_TABLE_COLUMNS = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    sorter: true,
    render: (value, row) =>
      row.is_hidden ? (
        <div style={{ textAlign: 'center' }}>
          {value}
          <Button
            size="small"
            onClick={() => {
              copyToClipboard(value);

              notification.success({
                message: 'Copy to clipboard',
                description: 'Artist Id has been copied.',
              });
            }}
            type="primary"
          >
            Copy Id
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <a href={getAuthorUrl(value)} target="_blank" rel="noopener noreferrer">
            {value}
          </a>

          <Button
            size="small"
            onClick={() => {
              copyToClipboard(value);

              notification.success({
                message: 'Copy to clipboard',
                description: 'Artist Id has been copied.',
              });
            }}
            type="primary"
          >
            Copy Id
          </Button>
        </div>
      ),
    fixed: true,
    width: 110,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    sorter: true,
    render: (value, row) =>
      row.is_hidden ? (
        value
      ) : (
        <a href={getAuthorUrl(row.id)} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      ),
    width: 200,
    fixed: true,
  },
  {
    title: 'High Priority',
    key: 'is_high_priority',
    dataIndex: 'is_high_priority',
    render: value => (value === 1 ? <div style={{ color: 'green' }}>Yes</div> : 'No'),
    width: 100,
  },
  {
    title: 'Hidden',
    key: 'is_hidden',
    dataIndex: 'is_hidden',
    render: value => (value === 1 ? <div style={{ color: 'red' }}>Yes</div> : 'No'),
    width: 80,
  },
  {
    title: 'Lots',
    key: 'lots_count',
    dataIndex: 'lots_count',
    sorter: true,
    render: (value, row) => (
      <a href={searchAuthorAllLotsURL(row.id)} target="_blank" rel="noopener noreferrer">
        {value || 0}
      </a>
    ),
    width: 80,
  },
  {
    title: 'Online',
    key: 'published_cnt',
    dataIndex: 'published_cnt',
    sorter: true,
    render: (value, row) => (
      <a href={searchAuthorPublishedLotsURL(row.id)} target="_blank" rel="noopener noreferrer">
        {value || 0}
      </a>
    ),
    width: 90,
  },
  {
    title: 'Qualifier',
    key: 'qualifier',
    dataIndex: 'qualifier',
    sorter: true,
    width: 200,
  },
  {
    title: 'Qualified Artist Id',
    key: 'qualified_artist_id',
    dataIndex: 'qualified_artist_id',
    sorter: true,
    width: 200,
  },
  {
    title: 'Status',
    key: 'artist_status',
    dataIndex: 'artist_status',
    sorter: true,
    width: 140,
    render: value => {
      const isPending = value === ARTIST_STATUS.PENDING;

      return <div style={{ color: isPending ? 'red' : '' }}>{value}</div>;
    },
  },
  {
    title: 'Nationality',
    key: 'nationality',
    dataIndex: 'nationality',
    sorter: false,
    width: 150,
  },
  {
    title: 'Birth / Established',
    key: 'birth',
    dataIndex: 'birth',
    sorter: true,
    width: 100,
  },
  {
    title: 'Death',
    key: 'death',
    dataIndex: 'death',
    sorter: true,
    width: 100,
  },
  {
    title: 'Birth/Death type',
    key: 'birth_death_type',
    dataIndex: 'birth_death_type',
    sorter: true,
    width: 150,
    render: value => {
      return <div>{birthDeathTypes[value]}</div>;
    },
  },
  {
    title: 'Alternate Spellings',
    key: 'alternate_spellings',
    dataIndex: 'alternate_spellings',
    sorter: true,
    width: 250,
    render: value => {
      return <div>{value ? value.slice(0, 50) : ''}{value && value.length > 50 ? '...' : ''}</div>;
    },
  },
  {
    title: 'Active Date',
    key: 'active_date',
    dataIndex: 'active_date',
    sorter: true,
    width: 200,
  },
];
