import { Request } from 'shared/tools/request';
import { UNPUBLISH_TYPE } from 'cleaning-lots/constants/unpublish';
import { createAction } from 'redux-actions';
import { getAuction } from './admin-cleaning';
import { notification } from 'antd';

const openUnpublishModal = createAction('OPEN_UNPUBLISH_MODAL');
const closeUnpublishModal = createAction('CLOSE_UNPUBLISH_MODAL');

const openNotification = (type, message, description) => {
  notification[type]({ message, description });
};

const unpublish = (selectedIds, unpublishType) => async dispatch => {
  try {
    if (unpublishType === UNPUBLISH_TYPE.ALL_LOTS) {
      const allResp = await Request.put('/api/raw-auction/unpublish', {
        data: {
          ids: selectedIds,
        },
      });
      if (!allResp || `${allResp.status}`[0] !== '2') throw new Error('Server error');
    }

    if (unpublishType === UNPUBLISH_TYPE.ALL_IGNORED_LOTS) {
      const ignoredResp = await Request.put('/api/raw-auction/unpublish-ignored', {
        data: {
          ids: selectedIds,
        },
      });
      if (!ignoredResp || `${ignoredResp.status}`[0] !== '2') throw new Error('Server error');
    }

    if (unpublishType === UNPUBLISH_TYPE.ALL_LP_IGNORED_LOTS) {
      const lpIgnResp = await Request.put('/api/raw-auction/unpublish-lp-ignored', {
        data: {
          ids: selectedIds,
        },
      });
      if (!lpIgnResp || `${lpIgnResp.status}`[0] !== '2') throw new Error('Server error');
    }

    dispatch(closeUnpublishModal());
    dispatch(getAuction());
  } catch (err) {
    dispatch(closeUnpublishModal());
    openNotification('error', 'Error has been occurred while unpublishing lots');
  }
};

export { openUnpublishModal, closeUnpublishModal, unpublish };
