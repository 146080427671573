import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Form, Input, InputNumber, Button, Row, Col, Spin, Popover } from 'antd';
import { ImageLoader } from 'cleaning-lots/components/CleaningUI/ImageLoader';
import { isNil, get } from 'lodash';
import { Request } from 'shared/tools/request';

import { getMediumColorByParserConfidence } from 'shared/tools/medium';

import { getAuthorUrl } from 'shared/tools/url';

import {
  cleanerReviewStatuses,
  cleanerReviewStatusesForPendingArtist,
  currencies,
  measurements,
  lotModel,
  mediumFinals,
} from 'cleaning-lots/constants/lot-fields';

import { ARTIST_STATUS } from 'shared/constants/app';

import './index.scss';

const { TextArea } = Input;

let timeOut = null;

class AddLotModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    addLot: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    lotToEdit: lotModel.isRequired,

    artistQualifiers: PropTypes.arrayOf(PropTypes.string.isRequired),
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    artistQualifiers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      artistsInput: null,
      artists: [],
      artistId: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  onOk = () => {
    const {
      form: { validateFieldsAndScroll },
      addLot,
    } = this.props;
    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        if (!fields.financial_guarantee) fields.financial_guarantee = true;
        addLot(fields);
      }
    });
  };

  setArtistId(id) {
    this.setState(prevState => {
      const state = Object.assign({}, prevState);
      state.artistId = id;
      state.artistsInput = '';
      this.props.form.setFieldsValue({ artist_name: '' });
      state.artists = [];
      return state;
    });
  }

  navigateToLot = navigateCallback => () => {
    const {
      form: { resetFields },
    } = this.props;
    resetFields();
    navigateCallback();
  };

  getArtistQualifiers = () => {
    const qualifier = this.props.lotToEdit.artist_qualifier;
    const { artistQualifiers } = this.props;

    if (!qualifier || (qualifier && artistQualifiers.includes(qualifier))) {
      return artistQualifiers;
    }

    return [qualifier, ...artistQualifiers];
  };

  getCleanerReviewStatuses = () => {
    const artistStatus = get(this.props.lotToEdit, ['artist', 'artist_status']);
    const isArtistPending = artistStatus === ARTIST_STATUS.PENDING;

    return this.props.isAdmin || !isArtistPending ? cleanerReviewStatuses : cleanerReviewStatusesForPendingArtist;
  };

  async doRequest(name) {
    let data = false;
    if (name && name.trim() !== '') {
      const response = await Request.post('/api/artist', {
        data: { name, limit: 5, es: true },
      });
      data = response.data;
    }
    if (data && data.rows.length > 0)
      this.setState(prevState => {
        const state = Object.assign({}, prevState);
        state.artists = data.rows;
        return state;
      });
    else
      this.setState(prevState => {
        const state = Object.assign({}, prevState);
        state.artists = [];
        return state;
      });
  }

  async handleChange(ev) {
    const { value } = ev.target;
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(async () => this.doRequest(value), 350);
  }

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      visible,
      closeModal,
      isLoading,
      lotToEdit,
    } = this.props;

    const modalTitle = (
      <div className="edit-lot__title">
        <span>Add Lot to auction # {lotToEdit.raw_auction_id}</span>
      </div>
    );

    const mediumLabel = <span>Medium Final</span>;

    return (
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !isFieldsTouched(),
          onClick: this.onOk,
        }}
        width="80vw"
        bodyStyle={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        {isLoading ? (
          <div className="edit-lot__spin">
            <Spin />
          </div>
        ) : (
          <Form onSubmit={this.onSubmit}>
            {getFieldDecorator('cleaner_id', {
              initialValue: lotToEdit.cleaner_id ? lotToEdit.cleaner_id : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_house_name', {
              initialValue: lotToEdit.auction_house_name ? lotToEdit.auction_house_name : null,
            })(<Input hidden />)}
            {getFieldDecorator('raw_auction_id', {
              initialValue: lotToEdit.raw_auction_id ? lotToEdit.raw_auction_id : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_house_id', {
              initialValue: lotToEdit.auction_house_id ? lotToEdit.auction_house_id : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_location', {
              initialValue: lotToEdit.auction_location ? lotToEdit.auction_location : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_name', {
              initialValue: lotToEdit.auction_name ? lotToEdit.auction_name : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_num', {
              initialValue: lotToEdit.auction_num ? lotToEdit.auction_num : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_start_date', {
              initialValue: lotToEdit.auction_start_date ? lotToEdit.auction_start_date : null,
            })(<Input hidden />)}
            {getFieldDecorator('auction_end_date', {
              initialValue:  lotToEdit.auction_end_date || lotToEdit.auction_start_date || null,
            })(<Input hidden />)}
            <Row gutter={24}>
              <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Lot Image">
                  {getFieldDecorator('lot_image_url', {
                    initialValue: {
                      fileList: [],
                    },
                  })(<ImageLoader accept="image/*" width="100%" />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 4 }} md={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item label="Lot origin URL">
                  {getFieldDecorator('lot_origin_url', {
                    initialValue: null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 5 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Cleaner Review Status">
                  {getFieldDecorator('cleaner_review_status', {
                    initialValue: null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {this.getCleanerReviewStatuses().map(reviewStatus => (
                        <Select.Option key={reviewStatus} value={reviewStatus}>
                          {reviewStatus}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Search Artist">
                  {getFieldDecorator('artist_name', {
                    initialValue: this.state.artistsInput ? this.state.artistsInput : '',
                  })(<Input onChange={this.handleChange} placeholder="Search by name" />)}
                  {this.state.artists.length > 0 && (
                    <div className="art-autosuggest">
                      {this.state.artists.map(artist => (
                        <div
                          key={artist.id}
                          className="art-autosuggest-item"
                          onClick={() => this.setArtistId(artist.id)}
                        >
                          {artist.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Item>
                <Form.Item label="Artist ID">
                  {getFieldDecorator('artist_id', {
                    initialValue: this.state.artistId ? this.state.artistId : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artist Qualifier">
                  {getFieldDecorator('artist_qualifier', {
                    initialValue: null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {this.getArtistQualifiers().map(qualifier => (
                        <Select.Option key={qualifier} value={qualifier}>
                          {qualifier}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 12 }}>
                <Form.Item label="Lot Number">
                  {getFieldDecorator('lot_num', {
                    initialValue: null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 8 }} md={{ span: 14 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Name">
                  {getFieldDecorator('artwork_name', {
                    initialValue: null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Creation Year">
                  {getFieldDecorator('artwork_creation_year', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }}>
                <Form.Item label="Artwork Markings">
                  {getFieldDecorator('artwork_markings', {
                    initialValue: null,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Edition Current">
                  {getFieldDecorator('artwork_edition_current', {
                    initialValue: null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Edition Size">
                  {getFieldDecorator('artwork_edition_size', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Currency">
                  {getFieldDecorator('currency', {
                    initialValue: null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {currencies.map(currency => (
                        <Select.Option key={currency} value={currency}>
                          {currency}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Estimate Min">
                  {getFieldDecorator('price_estimate_min', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Estimate Max">
                  {getFieldDecorator('price_estimate_max', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Sold">
                  {getFieldDecorator('price_sold', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Price Kind">
                  {getFieldDecorator('price_kind', {
                    initialValue: null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 2 }} md={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item label="Guaranteed">
                  {getFieldDecorator('financial_guarantee', { initialValue: false })(
                    <Select>
                      <Select.Option key="gYes" value={true}>
                        Yes
                      </Select.Option>
                      <Select.Option key="gNo" value={false}>
                        No
                      </Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Unit">
                  {getFieldDecorator('artwork_measurements_unit', {
                    initialValue: null,
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {measurements.map(measurement => (
                        <Select.Option key={measurement} value={measurement}>
                          {measurement}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Height">
                  {getFieldDecorator('artwork_measurements_height', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Width">
                  {getFieldDecorator('artwork_measurements_width', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Measurements Depth">
                  {getFieldDecorator('artwork_measurements_depth', {
                    initialValue: null,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Аrtwork Сondition In">
                  {getFieldDecorator('artwork_condition_in', {
                    initialValue: null,
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Materials">
                  {getFieldDecorator('artwork_materials', {
                    initialValue: null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>

              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label={mediumLabel}>
                  {getFieldDecorator('medium_final', {
                    initialValue: null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {mediumFinals.map(([value, name]) => (
                        <Select.Option key={value} value={value}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Description">
                  {getFieldDecorator('artwork_description', {
                    initialValue: null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Provenance">
                  {getFieldDecorator('artwork_provenance', {
                    initialValue: null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Exhibited">
                  {getFieldDecorator('artwork_exhibited', {
                    initialValue: null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Artwork Literature">
                  {getFieldDecorator('artwork_literature', {
                    initialValue: null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

export { AddLotModal };
