import { handleActions, combineActions } from 'redux-actions';
import produce from 'immer';
import {
  getLotsRequest,
  getLotsSuccess,
  getLotsError,
  setSelectedLots,
  setUnselectedLots,
  updateLotRequest,
  updateLotSuccess,
  updateLotError,
  bulkUpdateLotsRequest,
  bulkUpdateLotsSuccess,
  bulkUpdateLotsError,
  setFilters,
  setPage,
  setColumns,
  setColumnsWithUpdatedFilters,
  setActiveAuction,
  setToInitialState,
  setSortDirection,
  setSortBy,
  setLotToEdit,
  setBulkEditType,
  setSelectAll,
  publishLotsRequest,
  publishLotsSuccess,
  publishLotsError,
  unpublishLotsRequest,
  unpublishLotsSuccess,
  unpublishLotsError,
  setIsLoading,
  setPendingJobsCount,
  setTableFilters,
  setReviewRequiredReasons,
  setCurrencies,
  setPriceSold,
  setPriceKind,
} from 'cleaning-lots/actions/cleaning';

import {
  successProducer,
  errorProducer,
  receiveDataProducer,
  setSelectedIds,
  setUnselectedIds,
  setFiltersProducer,
  setPageProducer,
} from 'shared/helpers/producers';

import { getCleaningUiColumns } from 'cleaning-lots/constants/table-columns';

const initState = {
  isLoading: false,
  success: false,
  count: 0,
  pagesCount: 0,
  ids: [],
  items: {},
  filters: {},
  offset: 0,
  currentPage: 1,
  columns: getCleaningUiColumns(),
  activeAuctionId: null,
  selectedIds: [],
  unselectedIds: [],
  isAllSelected: false,
  sortDir: 'asc',
  sortBy: 'lot_num',
  lotToEdit: null,
};

export const lotsReducer = handleActions(
  {
    [setToInitialState]: produce(draft => {
      draft.isLoading = false;
      draft.success = false;
      draft.count = 0;
      draft.pagesCount = 0;
      draft.ids = [];
      draft.items = {};
      draft.filters = {};
      draft.offset = 0;
      draft.currentPage = 1;
      draft.columns = getCleaningUiColumns();
      draft.activeAuctionId = null;
      draft.selectedIds = [];
      draft.unselectedIds = [];
      draft.isAllSelected = false;
      draft.sortDir = 'asc';
      draft.sortBy = 'lot_num';
      draft.lotToEdit = null;
      draft.pendingJobsCount = 0;
    }),
    [getLotsRequest]: produce(draft => {
      draft.isLoading = true;
    }),
    [getLotsSuccess]: produce(receiveDataProducer),
    [getLotsError]: produce(errorProducer),
    [setSelectedLots]: produce(setSelectedIds),
    [setUnselectedLots]: produce(setUnselectedIds),
    [setActiveAuction]: produce((draft, action) => {
      draft.activeAuctionId = action.payload.auctionId;
      draft.isAllSelected = false;
      draft.selectedIds = [];
      draft.unselectedIds = [];
    }),
    [updateLotRequest]: produce(draft => {
      draft.isLoading = true;
    }),
    [updateLotSuccess]: produce(successProducer),
    [updateLotError]: produce(errorProducer),
    [bulkUpdateLotsRequest]: produce(draft => {
      draft.isLoading = true;
    }),
    [bulkUpdateLotsSuccess]: produce(successProducer),
    [bulkUpdateLotsError]: produce(errorProducer),
    [setFilters]: produce(setFiltersProducer),
    [setPage]: produce(setPageProducer),
    [setColumns]: produce((draft, action) => {
      draft.columns = getCleaningUiColumns().filter(
        column => action.payload.columns[column.key] || column.required,
      );
    }),
    [setColumnsWithUpdatedFilters]: produce((draft, action) => {
      draft.columns = action.payload.columns;
    }),
    [setSortDirection]: produce((draft, action) => {
      draft.sortDir = action.payload.sortDir;
    }),
    [setSortBy]: produce((draft, action) => {
      draft.sortBy = action.payload.sortBy;
    }),
    [setLotToEdit]: produce((draft, action) => {
      draft.lotToEdit = action.payload.lotToEdit;
    }),
    [setBulkEditType]: produce((draft, action) => {
      draft.bulkEditType = action.payload.bulkEditType;
    }),
    [setSelectAll]: produce((draft, action) => {
      draft.isAllSelected = action.payload.isAllSelected;
      draft.selectedIds = [];
      draft.unselectedIds = [];
    }),
    [combineActions(publishLotsRequest, unpublishLotsRequest)]: produce(draft => {
      draft.isLoading = true;
    }),
    [combineActions(publishLotsSuccess, unpublishLotsSuccess)]: produce(successProducer),
    [combineActions(publishLotsError, unpublishLotsError)]: produce(errorProducer),

    [setIsLoading]: produce((draft, action) => {
      draft.isLoading = action.payload.isLoading;
    }),
    [setPendingJobsCount]: produce((draft, action) => {
      draft.pendingJobsCount = action.payload.pendingJobsCount;
    }),
    [setTableFilters]: produce((draft, action) => {
      draft.tableFilters = action.payload.tableFilters;
    }),
    [setReviewRequiredReasons]: produce((draft, action) => {
      draft.reviewRequiredReasons = action.payload.reviewRequiredReasons;
    }),
    [setCurrencies]: produce((draft, action) => {
      draft.currencies = action.payload.currencies;
    }),
    [setPriceKind]: produce((draft, action) => {
      draft.priceKind = action.payload.priceKind;
    }),
    [setPriceSold]: produce((draft, action) => {
      draft.priceSold = action.payload.priceSold;
    }),
  },
  initState,
);
