import './index.scss';

import { Button, Table } from 'antd';

import AdminCleaningActions from 'cleaning-lots/components/AdminCleaningUI/AdminCleaningActions';
import AssignCleanerModal from 'cleaning-lots/containers/AssignCleanerModal';
import DeleteModal from 'cleaning-lots/containers/DeleteModal';
import EditAuctionModal from 'cleaning-lots/containers/EditAuctionModal';
import EditNotesModal from 'cleaning-lots/containers/EditNotesModal';
import EditPriorityModal from 'cleaning-lots/containers/EditPriorityModal';
import { ITEMS_PER_PAGE } from 'shared/constants/items-per-page';
import Layout from 'main/containers/Layout';
import PropTypes from 'prop-types';
import PublishModal from 'cleaning-lots/containers/PublishModal';
import React from 'react';
import Search from 'cleaning-lots/containers/AdminCleaningSearch';
import { TABS } from 'cleaning-lots/constants/cleaning-lots';
import Tabs from 'shared/components/Tabs';
import UnpublishModal from 'cleaning-lots/containers/UnpublishModal';
import { auctionModel } from 'cleaning-lots/constants/auction-model';

const orderLabels = {
  descend: 'desc',
  ascend: 'asc',
};

export default class AdminCleaningUI extends React.PureComponent {
  state = {
    isDeleteModalOpened: false,
    isAssignCleanerModalOpened: false,
    isEditAuctionModalOpened: false,
    auctionToEdit: null,
  };

  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    getAuction: PropTypes.func.isRequired,
    unpublishLots: PropTypes.func.isRequired,
    ignoreLots: PropTypes.func.isRequired,
    // finishCleaning: PropTypes.func.isRequired,
    setSelectedAuctions: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setToInitialState: PropTypes.func.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    setSortBy: PropTypes.func.isRequired,

    sortDir: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,

    count: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    isLoading: PropTypes.bool.isRequired,
    auctionRows: PropTypes.arrayOf(auctionModel).isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        dataIndex: PropTypes.string.isRequired,
        fixed: PropTypes.string,
        render: PropTypes.func,
      }),
    ).isRequired,
    openEditPriorityModal: PropTypes.func.isRequired,
    openPublishModal: PropTypes.func.isRequired,
    openUnpublishModal: PropTypes.func.isRequired,
    openNotesModal: PropTypes.func.isRequired,
    tableWidth: PropTypes.number.isRequired,
  };

  componentWillUnmount() {
    this.props.setToInitialState();
  }

  getAuction = () => {
    this.props.getAuction(this.state.filters);
  };

  onRowSelect = selectedRowKeys => {
    this.props.setSelectedAuctions(selectedRowKeys);
  };

  unpublishLots = () => {
    this.props.unpublishLots(this.props.selectedIds);
  };

  ignoreLots = () => {
    this.props.ignoreLots(this.props.selectedIds, this.props.auctionRows.filter(a => this.props.selectedIds.includes(a.id)));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      isDeleteModalOpened: !prevState.isDeleteModalOpened,
    }));
  };

  toggleAssignCleanerModal = () => {
    this.setState(prevState => ({
      isAssignCleanerModalOpened: !prevState.isAssignCleanerModalOpened,
    }));
  };

  onEditAuction = auction =>
    this.setState({
      auctionToEdit: auction,
      isEditAuctionModalOpened: true,
    });

  сloseEditLotModal = () =>
    this.setState({
      auctionToEdit: null,
      isEditAuctionModalOpened: false,
    });

  handleTableChange = (pagination, filters, sorter) => {
    const newSortDir = orderLabels[sorter.order];
    const newSortBy = sorter.field;
    const newPage = pagination.current;
    const { sortDir, sortBy, currentPage } = this.props;

    const isSortDirChanged = newSortDir !== sortDir;
    const isSortByChanged = newSortBy !== sortBy;
    const isPageChanged = newPage !== currentPage;

    if (isSortDirChanged) {
      this.props.setSortDirection(newSortDir);
    }

    if (isSortByChanged) {
      this.props.setSortBy(newSortBy);
    }

    if (isPageChanged) {
      this.props.setPage(newPage);
    }

    if (isPageChanged || isSortDirChanged || isSortByChanged) {
      this.props.getAuction();
    }
  };

  render() {
    const {
      isDeleteModalOpened,
      isAssignCleanerModalOpened,
      isEditAuctionModalOpened,
      auctionToEdit,
    } = this.state;

    const {
      isLoading,
      selectedIds,
      auctionRows,
      count,
      columns,
      currentPage,
      isAdmin,
      isSuperAdmin,
      tableWidth,
    } = this.props;

    const rowSelection = {
      selectedRowKeys: selectedIds,
      onChange: this.onRowSelect,
    };

    const selectedAuctions = auctionRows.filter(a => selectedIds.includes(a.id));

    return (
      <Layout className="admin-cleaning-ui">
        <Tabs items={TABS} className="admin-cleaning-ui__tabs" />
        <Search />
        <AdminCleaningActions
          isButtonsDisabled={!selectedIds.length}
          openDeleteModal={this.toggleDeleteModal}
          openAssignCleanerModal={this.toggleAssignCleanerModal}
          // finishCleaning={this.finishCleaning}
          openPublishModal={this.props.openPublishModal}
          openUnpublishModal={this.props.openUnpublishModal}
          openNotesModal={this.props.openNotesModal}
          unpublishLots={this.unpublishLots}
          ignoreLots={this.ignoreLots}
          openEditPriorityModal={this.props.openEditPriorityModal}
          selectedItemsCount={selectedIds.length}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
        />
        <AssignCleanerModal
          selectedIds={selectedIds}
          visible={isAssignCleanerModalOpened}
          closeModal={this.toggleAssignCleanerModal}
        />
        <DeleteModal
          selectedIds={selectedIds}
          visible={isDeleteModalOpened}
          closeModal={this.toggleDeleteModal}
        />
        <EditPriorityModal selectedIds={selectedIds} />
        <PublishModal selectedIds={selectedIds} />
        <UnpublishModal selectedIds={selectedIds} />
        <EditNotesModal selectedIds={selectedIds} />
        {auctionToEdit && (
          <EditAuctionModal
            isVisible={isEditAuctionModalOpened}
            closeModal={this.сloseEditLotModal}
            auctionToEdit={auctionToEdit}
          />
        )}
        <Table
          rowKey="id"
          scroll={{ x: tableWidth, y: 'calc(100vh - 550px)' }}
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={auctionRows}
          columns={[
            ...columns,
            {
              title: 'Edit',
              key: 'edit',
              dataIndex: 'edit',
              render: (value, record) => (
                <Button
                  type="primary"
                  shape="circle"
                  icon="edit"
                  onClick={() => this.onEditAuction(record)}
                />
              ),
              fixed: 'right',
              width: 70,
            },
          ]}
          size="middle"
          pagination={{
            pageSize: ITEMS_PER_PAGE,
            total: count,
            position: 'top',
            current: currentPage,
            size: 'small',
          }}
          onChange={this.handleTableChange}
        />
      </Layout>
    );
  }
}
